<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      //- 搜尋bar
      b-card.col-lg-12.mb-1(no-body)
        b-collapse#customaccordion-1(v-model="currentSearchVisible", accordion="accordion", role="tabpanel")
          b-card-body
            form.form-horizontal(role="form")
              .row
                .col-lg-4
                  .form-group.mb-3
                    label.col-form-label(for="form-control") 員工編號
                    input.form-control(v-model="SearchOptions.account_id", type="text", placeholder="請輸入完整員工編號")
                .col-lg-4
                  .form-group.mb-3
                    label.col-form-label(for="form-control") 姓名
                    input.form-control(v-model="SearchOptions.user_name", type="text")
                .col-lg-4
                  .form-group.mb-3.p-0.m-0.mt-1
                    label Email
                    br
                    .row
                      .col-6.d-flex.justify-content-center.align-items-center
                        input.form-control(v-model="SearchOptions.email_account", type="text")
                        span.ml-3.mt-1 @
                      .col-6
                        input.form-control(v-model="SearchOptions.email_domain", type="text")
              .row
                .col-lg-12.d-flex.justify-content-end.align-items-center
                  b-button.ml-5.width-md(variant="primary",@click="reloadDataList") 查詢
      //- 表格
      table.col-lg-12.mt-3
        .card
          .card-body
            .header-row.mb-3
              .header-title 帳號查詢結果
              //- b-button.width-md.mr-2(variant="primary") 匯出
            .table-responsive.mb-0
              b-table(
                ref="ManagerList"
                :items="getManagerList", 
                :fields="ManagerFields", 
                responsive, 
                :per-page="perPage", 
                :current-page="currentPage", 
                :sort-desc.sync="sortDesc", 
                :filter="filter", 
                :filter-included-fields="filterOn", 
                @filtered="onFiltered"
              )

                template(v-slot:cell(email)="row")
                  span(v-if="row.item.email") {{ row.item.email }}
                  span(v-else) -



                template(v-slot:cell(department)="row")
                  span(v-if="row.item.department.id") {{ row.item.department.name }}
                  span(v-else) -

                template(v-slot:cell(position)="row")
                  span(v-if="row.item.position.id") {{ row.item.position.name }}
                  span(v-else) -

                template(v-slot:cell(status)="row")
                  span(v-if="row.item.status == 0") 停用
                  span(v-if="row.item.status == 1") 正常
                  span(v-if="row.item.status == 2") 禁用

                template(v-slot:cell(edit)="row")
                  b-button.table-btn(variant="primary" :to="'/apmanagement-setting/' + row.item.id")
                    | 編輯
            .row.mb-md-2
              .col-12
                div(class="dataTables_paginate paging_simple_numbers float-right")
                  ul.pagination.pagination-rounded.mb-0
                    // pagination
                    b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  name: 'ManagerAccountList',
  data () {
    return {
      title: "帳號列表",
      items: [
        {
          text: "帳號/權限管理",
          href: "/apmanagement",
        }
      ],
      currentSearchVisible: true,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortDesc: false,
      SearchOptions: {
        account_id: "",
        user_name: "",
        email_account: "",
        email_domain: ""
      },
      ManagerFields: [
        { key: "account_id", label: "員工編號", sortable: false, class: 'width-auto' },
        { key: "name", label: "姓名", sortable: false, class: 'width-auto' },
        { key: "email", label: "Email", sortable: false, class: 'width-auto' },
        { key: "department", label: "部門", sortable: false, class: 'width-auto' },
        { key: "position", label: "職稱", sortable: false, class: 'width-auto' },
        { key: "status", label: "帳戶狀態", sortable: false, class: 'width-auto' },
        { key: "created_at", label: "建立時間", sortable: false, class: 'width-auto' },
        { key: 'edit', label: "操作", sortable: false, stickyColumn: true, class: 'width-auto text-center' },
      ],
    };
  },
  computed: {
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  methods: {
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    reloadDataList () {
      this.$refs.ManagerList.refresh()
    },
    // 獲得管理員清單
    async getManagerList (_table, _callback) {
      let vm = this
      this.SearchOptions.page = this.currentPage
      await this.$root.apis.getManagerAccountList(this.SearchOptions, async function (_response) {
        let response = _response.body.data
        vm.totalRows = response.total
        vm.currentPage = response.page
        vm.perPage = response.per_page

        if (typeof _callback === 'function') {
          await _callback(response.managers)
        }
      })
    }
  }
};
</script>

<style lang="sass" scoped>
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
  
</style>
